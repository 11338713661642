<template>
  <!-- 组合产品管理 -->
  <div class="app-container">
    <avue-crud
      ref="crud"
      class="flex-one-page"
      :option="tableOption"
      :data="tableData"
      :page="tablePage"
      :tableLoading="tableLoading"
      @size-change="sizeChange"
      @current-change="pageChange"
      @search-change="searchChange"
    >
      <template #create_timeSearchForm="{ form }">
        <SaleDateTime :time.sync="form['create_time']" @change="searchChange"></SaleDateTime>
      </template>

      <template #combinedInfo="{ row }">
        <div>
          <span>中文名称：</span>
          <span>{{ row.combinedChineseName }}</span>
        </div>
        <div>
          <span>英文名称：</span>
          <span> {{ row.combinedEnglishName }}</span>
        </div>
        <div>
          <span>自定义编码：</span>
          <span> {{ row.combinedCustomProductCode }}</span>
        </div>
      </template>

      <template #combinedProduct="{ row }">
        <div
          class="combined-product"
          :key="index"
          v-for="({  name, styleDisplayImageList }, index) in productPrototypeList(row)"
        >
          <div class="img-list-wrapper">
            <DefaultImg :content="name" :isCarousel="true" :carouselList="styleDisplayImageList" />
          </div>
          <div class="name">
            {{ name }}
          </div>
        </div>
      </template>

      <template #designCombinedProduct="{ row }">
        <div
          class="combined-product"
          :key="index"
          v-for="({ name, styleDisplayImageList }, index) in productPrototypeList(row)"
        >
          <div class="img-list-wrapper">
            <DefaultImg :content="name" :isCarousel="true" :carouselList="styleDisplayImageList" />
          </div>
          <div class="name">
            {{ name }}
          </div>
        </div>
      </template>

        <!-- 组合产品颜色列表 -->
      <template #combinedColorName="{ row }">
        <TagList :data="combinedColorNameList(row)" />
      </template>

       <!-- 组合产品尺码列表 -->
      <template #combinedSizeName="{ row }">
        <TagList :data="combinedSizeNameList(row)" />
      </template>

      <template #menu="{ row }">
        <Detail v-if="hasDetail" :sup_this="sup_this" :data="row" />
      </template>
    </avue-crud>
  </div>
</template>

<script>
import avueCrud from '@/mixins/avueCrud'
import SaleDateTime from '@/components/saleDateTime'

import DefaultImg from '@/views/components/defaultImg'

import Detail from './module/detail'
import TagList from './module/tagList'

import { tableOption } from './const'
import { initData } from '@/api/data'

import { getProductPrototypeList } from '@/utils'

import uniq from 'lodash/uniq'

export default {
  props: {
    url: {
      type: String,
      default: '/externaladmin/productService/combinedProductPrototype/list'
    },

    resetTableOption: {
      type: Object,
      required: true
    },

    resetMergeData: {
      type: Object,
      default: () => ({})
    },

    hasDetail: Boolean
  },

  mixins: [
    avueCrud({
      tableOption
    })
  ],

  components: {
    SaleDateTime,
    DefaultImg,
    TagList,
    Detail
  },

  data() {
    return {
      sup_this: this,
      tableOption: { ...tableOption, ...this.resetTableOption }
    }
  },

  computed: {
    productPrototypeList() {
      return (row) => {
        return getProductPrototypeList(row)
      }
    },

    combinedProductStyleList() {
      return (row) => {
        return $GET(row, 'combinedProductStyleList', [])
      }
    },

    combinedColorNameList({ combinedProductStyleList }) {
      return (row) => {
        return combinedProductStyleList(row).map(({ combinedColorName }) => combinedColorName)
      }
    },

    combinedSizeNameList({ combinedProductStyleList }) {
      return (row) => {
        let tmpArr = []
        combinedProductStyleList(row).map((product) => {
          tmpArr.push(...$GET(product, 'combinedProductSizeList', []).map(({ combinedSizeName }) => combinedSizeName))
        })
        return uniq(tmpArr)
      }
    }
  },

  methods: {
    beforeInit() {
      this.tableData = []
    },

    getList(data) {
      return initData(this.url, 'post', { ...this.resetMergeData, ...data })
    }
  }
}
</script>
<style lang="scss" scoped>
.combined-product {
  border-bottom: 1px solid #ebeef5;
  margin: 0 -10px;
  padding: 5px 10px;
  .name {
    @include ellipsis(2);
    text-align: center;
  }
  .img-list-wrapper {
    .default-img-popover-wrapper {
      margin: auto;
    }
  }
}
.combined-product:last-child {
  border-bottom: none;
}
</style>
