//组合茶农详情
export const combinationPoductDetailFiled = {
  labelWidth: 110,
  menuBtn: false,
  column: [
    {
      label: '产品中文名称',
      prop: 'combinedChineseName',
      span: 24,
      row: true,
      formslot: true
    },
    {
      label: '产品英文名称',
      prop: 'combinedEnglishName',
      span: 24,
      formslot: true,
      row: true
    },
    {
      label: '关联原型产品',
      prop: 'productCode',

      span: 24,
      row: true,
      formslot: true
    },
    {
      label: '颜色尺寸明细',
      prop: 'color',
      span: 24,
      row: true,
      formslot: true
    }
  ]
}
