<template>
  <BaseDialog minHeight="200px" :title="title" :dialogVisible.sync="dialog" width="1400px" :hiddenFooter="true">
    <avue-form ref="form" v-model="data" :option="option">
      <template #combinedChineseName>
        {{ data.combinedChineseName || '暂无' }}
      </template>

      <template #combinedEnglishName>
        {{ data.combinedEnglishName || '暂无' }}
      </template>

      <template #productCode>
        <ProtoCardList :data="productPrototypeList" />
      </template>

      <template #color>
        <avue-crud
          style="max-height: 500px"
          class="combination-poduct-avue-crud"
          :key="key"
          :data="tableData"
          :option="tableOption"
          :spanMethod="spanMethod"
        >
          <template
            #[prop]="{ row }"
            v-for="({ prop, sizeIdList, styleIdList }, index) in tableOption.column.slice(2) || []"
          >
            <div :key="index" v-if="sizeInfo(row, sizeIdList, styleIdList)">
              <span>尺码：</span><span class="mr15">{{ sizeInfo(row, sizeIdList, styleIdList).sizeName }}</span>
              <span>件数：</span><span class="mr15">{{ sizeInfo(row, sizeIdList, styleIdList).productCount }}</span>
            </div>
          </template>
        </avue-crud>
      </template>
    </avue-form>
    <template #footer>
      <span></span>
    </template>
  </BaseDialog>
</template>
<script>
import ProtoCardList from './module/protoCardList'

import { combinationPoductDetailFiled as formField } from './field'
import { commonFromMixin } from '@/mixins'
import { getProductPrototypeList } from '@/utils'

import { getUUID } from '@/utils'
import cloneDeep from 'lodash/cloneDeep'

let preId = undefined

export default {
  mixins: [commonFromMixin],

  components: {
    ProtoCardList
  },

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      key: getUUID(),
      option: formField
    }
  },

  computed: {
    productPrototypeList({ data }) {
      return getProductPrototypeList(data)
    },

    sizeInfo() {
      return (row, sizeIdList, styleIdList) => {
        let findItem = null
        $GET(row, 'combinedProductSizeAssociationList', []).find((item) => {
          const { productSizeId, sizeName, productCount, productStyleId } = item
          if (sizeIdList?.includes(productSizeId) && styleIdList?.includes(productStyleId)) {
            findItem = {
              sizeName,
              productCount
            }
            return true
          }
        })
        return findItem
      }
    },

    tableData({ data }) {
      const cloneData = cloneDeep(data)
      const tmpArr = []
      $GET(cloneData, 'combinedProductStyleList', []).map((combinedProduct) => {
        const { combinedColorName, id } = combinedProduct
        $GET(combinedProduct, 'combinedProductSizeList', []).map((combinedProductSize) => {
          tmpArr.push({
            ...combinedProductSize,
            combinedColorName: combinedColorName,
            combinedProductStyleId: id
          })
        })
      })
      return tmpArr
    },

    tableOption({ data }) {
      const column = [
        {
          label: '组合产品颜色',
          prop: 'combinedColorName'
        },
        {
          label: '组合产品尺码',
          prop: 'combinedSizeName'
        }
      ]

      $GET(data, 'productPrototypeList', []).map((item, index) => {
        const { chineseName, sizeList, styleList } = item
        column.push({
          label: chineseName,
          prop: `productPrototype_${index}`,
          sizeIdList: sizeList?.map(({ id }) => id),
          styleIdList: styleList.map(({ id }) => id),
          slot: true
        })
      })

      this.key = getUUID()

      return {
        menu: false,
        size: 'mini',
        column
      }
    }
  },

  methods: {
    spanMethod({ row, column, rowIndex, columnIndex }) {
      const { combinedProductStyleId } = row
      if (rowIndex == 0 && columnIndex == 0) {
        preId = undefined
      }
      let num = 0
      if (columnIndex != 0) {
        return [1, 1]
      }
      if (combinedProductStyleId == preId) {
        return [0, 0]
      } else {
        preId = combinedProductStyleId
      }
      for (let i = 0; i < this.tableData.length; i++) {
        const item = this.tableData[i]
        if (item.combinedProductStyleId == preId) {
          num++
        } else {
          continue
        }
      }
      return [num, 1]
    }
  }
}
</script>
<style lang="scss">
.combination-poduct-avue-crud {
  .el-table {
    border-left: 1px solid #ebeef5;
  }
}
</style>