var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container"},[_c('avue-crud',{ref:"crud",staticClass:"flex-one-page",attrs:{"option":_vm.tableOption,"data":_vm.tableData,"page":_vm.tablePage,"tableLoading":_vm.tableLoading},on:{"size-change":_vm.sizeChange,"current-change":_vm.pageChange,"search-change":_vm.searchChange},scopedSlots:_vm._u([{key:"create_timeSearchForm",fn:function(ref){
var form = ref.form;
return [_c('SaleDateTime',{attrs:{"time":form['create_time']},on:{"update:time":function($event){return _vm.$set(form, 'create_time', $event)},"change":_vm.searchChange}})]}},{key:"combinedInfo",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('span',[_vm._v("中文名称：")]),_c('span',[_vm._v(_vm._s(row.combinedChineseName))])]),_c('div',[_c('span',[_vm._v("英文名称：")]),_c('span',[_vm._v(" "+_vm._s(row.combinedEnglishName))])]),_c('div',[_c('span',[_vm._v("自定义编码：")]),_c('span',[_vm._v(" "+_vm._s(row.combinedCustomProductCode))])])]}},{key:"combinedProduct",fn:function(ref){
var row = ref.row;
return _vm._l((_vm.productPrototypeList(row)),function(ref,index){
var name = ref.name;
var styleDisplayImageList = ref.styleDisplayImageList;
return _c('div',{key:index,staticClass:"combined-product"},[_c('div',{staticClass:"img-list-wrapper"},[_c('DefaultImg',{attrs:{"content":name,"isCarousel":true,"carouselList":styleDisplayImageList}})],1),_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(name)+" ")])])})}},{key:"designCombinedProduct",fn:function(ref){
var row = ref.row;
return _vm._l((_vm.productPrototypeList(row)),function(ref,index){
var name = ref.name;
var styleDisplayImageList = ref.styleDisplayImageList;
return _c('div',{key:index,staticClass:"combined-product"},[_c('div',{staticClass:"img-list-wrapper"},[_c('DefaultImg',{attrs:{"content":name,"isCarousel":true,"carouselList":styleDisplayImageList}})],1),_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(name)+" ")])])})}},{key:"combinedColorName",fn:function(ref){
var row = ref.row;
return [_c('TagList',{attrs:{"data":_vm.combinedColorNameList(row)}})]}},{key:"combinedSizeName",fn:function(ref){
var row = ref.row;
return [_c('TagList',{attrs:{"data":_vm.combinedSizeNameList(row)}})]}},{key:"menu",fn:function(ref){
var row = ref.row;
return [(_vm.hasDetail)?_c('Detail',{attrs:{"sup_this":_vm.sup_this,"data":row}}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }