<template>
  <div class="tag-list-component">
    <el-tag v-for="(item, index) in data" :key="index" effect="plain">
      {{ item }}
    </el-tag>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.tag-list-component {
  .el-tag {
    margin: 5px;
  }
}
</style>