<template>
  <div class="protoCardList">
    <div class="item" :key="index" v-for="({ name, styleDisplayImageList }, index) in data">
      <DefaultImg class="img" :content="name" :isCarousel="true" :carouselList="styleDisplayImageList" />
      <div class="info">
        <div class="name">
          {{ name }}
        </div>
        <!-- <div class="text-primary">￥{{ (price || 0) | currencyFormat }}1</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import DefaultImg from '@/views/components/defaultImg'

export default {
  props: {
    data: {
      type: Array,
      required: true
    }
  },

  components: {
    DefaultImg
  }
}
</script>

<style lang="scss" scoped>
.protoCardList {
  width: 100%;
  display: inline-block;
  .item {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    line-height: 1.25;
    box-sizing: border-box;
    width: 25%;
    height: 100px;
    padding: 15px;
    border-right: 1px solid $border-color;
    border-top: 1px solid $border-color;
    &.item:nth-child(4n + 1) {
      border-left: 1px solid $border-color;
    }
    &.item:nth-child(4n + 1) {
      border-left: 1px solid $border-color;
    }

    &.item:nth-last-child(-n + 4) {
      border-bottom: 1px solid $border-color;
    }

    .img {
      display: inline-block;
      margin-right: 10px;
      overflow: hidden;
    }
    .info {
      flex: 1;
      .name {
        margin-bottom: 5px;
        @include overflow-more(3);
      }
    }
  }
}
</style>