//组合产品
import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'

//组合产品管理列表
export function combineProductList(data, menuType = 'menu') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/combinedProductPrototype/list',
    method: 'post',
    data
  })
}

//组合产品管理详情
export function combineProductDetail(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/combinedProductPrototype/detail',
    method: 'post',
    data
  })
}


//定制组合产品管理列表
export function designCombinedProductList(data, menuType = 'menu') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/combinedProductPrototypeCollection/list',
    method: 'post',
    data
  })
}

//定制组合产品管理列表详情
export function designCombinedProductDetail(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/productService/combinedProductPrototypeCollection/detail',
    method: 'post',
    data
  })
}
