<template>
  <div class="line inline-block">
    <ColorTextBtn size="small" @click="goDetail" :loading="loading"> 详情 </ColorTextBtn>
    <DetailForm
      ref="form"
      v-if="dialogVisible"
      :dialogVisible.sync="dialogVisible"
      :sup_this="sup_this"
      :isAdd="false"
      :data="detailData"
      title="组合产品详情"
    />
  </div>
</template>

<script>
import DetailForm from './form'
import { commonEheaderMixin } from '@/mixins'
import { combineProductDetail as getDetail } from '@/api/product/combinationPoductApi'

export default {
  mixins: [commonEheaderMixin],
  components: { DetailForm },
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      loading: false,
      detailData: {}
    }
  },

  methods: {
    async goDetail() {
      this.loading = true
      try {
        let { id } = this.data
        const { code, detail } = await getDetail({id})
        if ($SUC({ code })) {
          this.detailData = detail
          this.showDialog()
        }
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
